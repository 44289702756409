<template>
  <div class="About">
    <div class="whoweare">
      <div class="whoweare__text">
        <div class="contents">
          <h1>WHO WE ARE</h1>
          <p>
            Evolve is a software company and digital<br />
            agency. It started in October 2014, and it <br />
            was founded to create empowering <br />
            digital solutions for private, public and <br />
            non-profit sector in Rwanda.
          </p>
        </div>
      </div>
      <div class="whoweare__image">
        <img src="@/assets/home/TEAM.jpg" />
      </div>
    </div>

    <div class="moreinfo">
      <div class="bg-container">
        <div class="background-about"></div>
      </div>

      <div class="mission">
        <div class="mission_content">
          <h1>OUR MISSION</h1>
          <p>
            eVolve Ltd was founded to provide <br />
            empowering ICT solutions to various <br />
            organisations within the private,<br />
            public and non-profit sector across<br />
            Africa.
          </p>
          <img src="@/assets/svg/mission.svg" />
        </div>
        <div class="mission_image image1">
          <!-- <img src="@/assets/home/bernard-hermant.jpg" /> -->
        </div>
      </div>

      <div class="mission">
        <div class="mission_image image2">
          <!-- <img src="@/assets/home/matt-noble.jpg" /> -->
        </div>

        <div class="mission_content">
          <h1>OUR VISION</h1>
          <p>
            We aim to stand out from the <br />
            competition by providing<br />
            personalised, professional ICT<br />
            sevices and customer service with a<br />
            personal touch. We aim to be <br />
            professional, reliable in our daily<br />
            transactions and go for a win <br />
            win situation on every single transaction.
          </p>
          <img src="@/assets/svg/vision.svg" class="vision" />
        </div>
      </div>
    </div>

    <div class="team">
      <div class="team__header">
        <h1>Our Team</h1>
      </div>

      <div class="team__images">
        <div
          class="team__images__container"
          v-for="member in members"
          :key="member.id"
        >
          <img class="" :src="require(`@/assets/home/team/${member.image}`)" />
          <div class="hover-contents">
            <h3>{{ member.name }}</h3>
            <p>{{ member.position }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LayoutDefault from "../layouts/LayoutDefault.vue";
import Teams from "../database/team.json";

export default {
  name: `About`,
  created() {
    // this.$emit(`update:layout`, LayoutDefault);
  },
  data() {
    return {
      members: Teams,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss">
.whoweare {
  display: flex;
  margin-top: 3rem;
  margin-bottom: 20rem;

  @include respond(tab-land) {
    margin-top: 10rem;
    margin-bottom: 0;
  }
  @include respond(phone) {
    flex-direction: column-reverse;
  }
  &__text {
    background: $color-white;
    clip-path: polygon(0 0, 100% 0, 81% 100%, 0 100%);
    width: 45%;
    height: 70vh;
    position: relative;
    @include respond(tab-land) {
      height: 25vh;
    }
    @include respond(phone) {
      height: 40vh;
      clip-path: initial;
    }
    @include respond(phone) {
      width: 100%;
    }
    .contents {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: fit-content;
      @include respond(phone) {
        left: 40%;
      }
      p {
        font-size: 1.4rem;
        margin-top: 4rem;
      }
      h1 {
        color: #1e1e1e;
        font-weight: 600;
      }
    }
  }
  &__image {
    flex: 1;
    background-repeat: no-repeat;
    background-size: cover;
    clip-path: polygon(34% 0, 100% 0, 100% 100%, 15% 100%);
    background-size: 100%;
    @include respond(phone) {
      clip-path: initial;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.moreinfo {
  .mission {
    display: flex;
    justify-content: space-between;
    margin-top: 10rem;
    gap: 5rem;
    @include respond(phone) {
      flex-direction: column-reverse;
      margin-top: 5rem;
    }
    &_content {
      width: 50%;
      background: $color-white;
      box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
      -webkit-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
      -moz-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
      position: relative;
      @include respond(phone) {
        margin: 0;
        padding: 0;
        width: 97%;
        height: fit-content;
        display: block;
        margin: auto;
        &:first-child {
          margin-top: 5rem;
        }
        &:last-child {
          margin-bottom: 5rem;
        }
      }
      h1 {
        text-align: center;
        font-size: 4rem;
        font-weight: 900;
        letter-spacing: 0.4rem;
        color: $color-primary;
        margin-top: 6rem;
      }
      p {
        position: absolute;
        top: 30%;
        left: 20%;
        font-size: 1.8rem;
        color: #373737;
        @include respond(phone) {
          position: relative;
          left: 5%;
          right: 0;
        }
      }

      img {
        width: 50%;
        position: absolute;
        bottom: -5rem;
        left: -1rem;
        filter: grayscale(100%);
        @include respond(tab-port) {
          bottom: -3rem;
        }
        @include respond(phone) {
          position: relative;
          bottom: -3.5rem;
        }
      }
      .vision {
        width: 45%;
        bottom: 1rem;
      }
    }
    &_image {
      width: 45%;
      @include respond(phone) {
        width: 100%;
      }
      img {
        box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
        -webkit-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
        -moz-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
      }
    }
    .mission_image {
      width: 50%;
      height: 55rem;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      @include respond(phone) {
        width: 100%;
      }
    }
    .image1 {
      background-image: url("../assets/home/bernard-hermant.jpg");
    }
    .image2 {
      background-image: url("../assets/home/matt-noble.jpg");
    }
  }
}

.team {
  margin-top: 15rem;
  display: none !important;
  &__header {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 2.5rem;
    color: $color-white;
    font-weight: bolder;
    letter-spacing: 5px;
  }
  &__images {
    display: flex;
    align-items: center;
    gap: 4%;
    flex-wrap: wrap;
    @include respond(phone) {
      flex-direction: column;
    }
    &__container {
      width: 22%;
      height: 35rem;
      position: relative;
      margin-top: 10rem;
      &:after {
        content: "";
        height: 0%;
        width: 0%;
        background: white;
        margin: auto;
        opacity: 0.5;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: 1;
        transition: all 2s ease;
      }
      &:hover::after {
        width: 100%;
        height: 100%;
      }
      position: relative;
      @include respond(phone) {
        width: 100%;
        height: 100%;
        margin-top: 2rem;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .hover-contents {
        @include absCenter;
        visibility: hidden;
        // transition: all 0.5s ease;
        p {
          margin-top: 2rem;
        }
      }
      &:hover .hover-contents {
        transition-delay: 1s;
        animation: fadeInOpacity;
        animation-duration: 3s;
        visibility: visible;
        z-index: 9999999999;
      }
    }
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.bg-container {
  position: relative;

  .background-about {
    height: 155rem;
    position: absolute;
    top: 20rem;
    right: -5rem;
    background: #202056;
    width: 90%;
    z-index: -1;
    @include respond(tab-land) {
      height: 140vh;
    }
    @include respond(tab-port) {
      display: none;
    }
    @include respond(phone) {
      display: none;
    }
  }
}
</style>
